















import { Component, Vue } from 'vue-property-decorator'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import __ from '@/shared/helpers/__'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import HseRoutineTemplate from "@/shared/modules/hse-routine-templates/hse-routine-templates.model";
import { getNodeApiUrlByVersion } from "@/config";
import AreYouSureDialog from "@/shared/components/dialogs/AreYouSureDialog.vue";
import HseRoutineTemplateService from "@/shared/modules/hse-routine-templates/hse-routine-templates.service";
import { CompanyRoutes } from "@/shared/router/company";
import SelectAction from "@/shared/classes/components/data-table/data-table-select-actions";
import SelectedRoutineAssign from "@/company/components/hse-routines/SelectedRoutineAssign.vue";
import _ from "lodash"
import baseHttp from "@/shared/http";
import RoleCan from "@/shared/components/RoleCan.vue";
import { RoleLevel } from "@/shared/modules/role/role.model";
import roleCan from "@/shared/helpers/role-can.helper";
import RoutineTemplateNameHeader from "@/company/components/hse-routines/RoutineTemplateNameHeader.vue";

@Component({
  methods: { __ },
  components: { DataTable, RoleCan },
})
export default class TemplatesHseRoutines extends Vue {
  table: DataTableBase | null = null
  selectedItems: HseRoutineTemplate[] = []
  http = baseHttp
  RoleLevel = RoleLevel

  created() {
    this.table = new DataTableBase()
      .setModel(HseRoutineTemplate)
      .setEndpoint(`${getNodeApiUrlByVersion()}/hse-routines/templates`)
      .setGroupBy('hseRoutineCategoryName')
      .setGroupDesc(false)
      .setPaginationNotVisible(true)
      .setMultipleCheckbox(roleCan([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin]))
      .setDoubleClick(this.navigateToEdit)
      .setGroupSelect(true)
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setSortable(false)
          .setValueComponent(RoutineTemplateNameHeader)
          .setText(__('company.views.hse-routines.templates.table.columns.name')),
      ])
      .setSelectActions([
        new SelectAction()
          .setComponent(SelectedRoutineAssign)
          .setEnabled(true)
          .setMeta({
            onSubmit: (data: any) => {
              const dataToSubmit = {
                responsibleUserUuid: data.responsibleUserUuid,
                templateUuids: this.table?.selected.map(item => item.uuid),
              }

              this.http.post(`${getNodeApiUrlByVersion()}/assigned-hse-routines/assign`, dataToSubmit)
                .then(() => {
                  this.$store.dispatch(GlobalActions.showSnackBar, {
                    type: SnackBarTypes.success,
                    message: __("company.views.hse-routines.templates.table.actions.assign.on-success"),
                  });
                  this.table!.data.map(item => {
                    if (item.selected) item.selected = false;
                  });
                })
                .catch();
            }
          })
      ])
      .setActions([
        new EditAction()
          .setAllowedForRole([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin])
          .setAction(this.navigateToEdit),
        new DeleteAction()
          .setAllowedForRole([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin])
          .setAllowedForItem((item: HseRoutineTemplate) => item.isCompanyTemplate)
          .setAction(this.delete)
      ])

    this.table.classes.tableCard = 'elevation-0'
    this.table.noPadding = true
  }

  navigateToCreate(): void {
    this.$router.push({ name: CompanyRoutes.hseRoutineTemplateCreate })
  }

  navigateToEdit(item: HseRoutineTemplate): void {
    this.$router.push({ name: CompanyRoutes.hseRoutineTemplateEdit, params: { uuid: item.uuid } })
  }

  delete(hseRoutineTemplate: HseRoutineTemplate): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.hse-routines.templates.table.actions.delete.title'),
        text: __('company.views.hse-routines.templates.table.actions.delete.text', { name: hseRoutineTemplate.name }),
        onYes: () => HseRoutineTemplateService.destroyCompany(hseRoutineTemplate.uuid)
          .then(() => {
            this.table?.removeItem(hseRoutineTemplate)
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.views.hse-routines.templates.table.actions.delete.on-success')
            })
          })
      }
    })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
