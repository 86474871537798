



import { Component, Prop, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import { GlobalActions } from "@/shared/store/global/global.actions";
import AreYouSureDialog from "@/shared/components/dialogs/AreYouSureDialog.vue";
import FormBase from "@/shared/classes/form/form-base";
import Form from "@/shared/components/form/Form.vue";
import { Positions } from "@/shared/interfaces/classes/form.interfaces";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import { validateFields } from "@/shared/helpers/validate";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";

@Component({
  components: { Form },
})
export default class SelectedRoutineAssign extends Vue {
  @Prop() meta!: any
  form: FormBase | null = null
  showForm: boolean = true

  created() {
    this.form = new FormBase()
      .setInline(true)
      .setFields([
        new SearchableField()
          .setKey('responsibleUserUuid')
          .setDense(true)
          .loadItems({
            endpoint: `company/${ this.company.slug }/employees`,
            value: 'uuid',
            title: 'full_name',
            perPage: 20,
          })
          .setTitle(__('company.components.hse-routines.selected-assign.form.responsible'))
          .isRequired(),
      ])
      .setBeforeSubmit(this.beforeSubmit)
      .setOnSuccess(this.meta.onSubmit)
      .setValidate((data: any) => {
        const errors = validateFields(data, this.form?.fields, { onError: this.onError });
        if (errors) {
          this.form?.setErrors(errors);
          return false;
        }

        return true;
      })
      .setOnError(() => {
        this.showForm = false

        this.$nextTick(() => {
          this.showForm = true
        })
      })
      .setSubmit({
        color: 'primary',
        position: Positions.right,
        class: 'ml-2',
        text: __('general.assign'),
      })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  private onError() {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.error,
      message: __("company.views.hse-routines.templates.table.actions.assign.on-error"),
    });
  }

  beforeSubmit(): Promise<void> {
    return new Promise((resolve) => {
      this.$store.dispatch(GlobalActions.showDialog, {
        show: true,
        component: AreYouSureDialog,
        meta: {
          title: __('company.views.hse-routines.templates.table.actions.assign.confirm-title'),
          text: __('company.views.hse-routines.templates.table.actions.assign.confirm-text'),
          onYes: () => resolve(),
        },
      })
    })
  }
}
