





import { Component, Prop, Vue } from 'vue-property-decorator'
import roleCan from "@/shared/helpers/role-can.helper";

@Component
export default class RoleCan extends Vue {
  @Prop() roles!: number[]

  get canAccess(): boolean {
    return roleCan(this.roles)
  }
}
