import Service from '@/shared/classes/service'
import { getNodeApiUrlByVersion } from "@/config";

export default class HseRoutineTemplateService extends Service {
  static prefix = 'hse-routine-templates'
  static companyPrefix = 'company-hse-routine-templates'

  static destroy(id: string): Promise<any> {
    return this.delete(`${getNodeApiUrlByVersion()}/${ this.prefix }/${ id }`)
  }

  static destroyCompany(id: string): Promise<any> {
    return this.delete(`${getNodeApiUrlByVersion()}/${ this.companyPrefix }/${ id }`)
  }

  static show(id: string): Promise<any> {
    return this.get(`${getNodeApiUrlByVersion()}/${ this.prefix }/${ id }`)
  }
}
